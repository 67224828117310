import React from "react"
import {
    pageTitle,
    pageSection,
    colors,
    fontSizes,
    desktopMediaQuery,
    centerContent,
    backgroundPatterns,
} from "../styles/constants"
import { graphql } from "gatsby"
import PWithBr from "../components/PWithBr"
import { css } from "@emotion/core"

function OurStoryPage({ data }) {
    return (
        <div css={[styles.root, backgroundPatterns.darkBrown]}>
            <h1 className="title is-1 has-text-centered" css={pageTitle}>
                {data.markdownRemark.frontmatter.title}
            </h1>
            <section css={[pageSection, centerContent]}>
                <PWithBr
                    css={css`
                        color: ${colors.gray};
                        line-height: 2rem;
                        max-width: 70rem;
                        font-size: ${fontSizes.default};
                        & p {
                            color: ${colors.coldGray};
                        }
                        ${desktopMediaQuery} {
                            padding: 0 10rem;
                            font-size: ${fontSizes.medium};
                            & p {
                                text-align: center !important;
                            }
                        }
                    `}
                >
                    {data.markdownRemark.frontmatter.story}
                </PWithBr>
            </section>
        </div>
    )
}

export default OurStoryPage

const styles = {
    root: css`
        width: 100%;
    `,
}

export const runesPageQuery = graphql`
    query OurStoryPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            frontmatter {
                title
                story
            }
        }
    }
`
